<!--
 * @Author: wjc
 * @Date: 2023-11-08 16:13:03
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-07-29 15:38:45
 * @Description: 
-->
<template>
  <div
    class="flex justify-between my-48px h-240px md:overflow-hidden md:flex-row lt-xs:flex-col lt-xs:h-auto lt-xs:hidden"
  >
    <div
      class="h-full mr-16px md:w-240px md:mb-0px xs:w-full lt-xs:mb-16px lt-xs:mr-0px lt-xs:hidden"
    >
      <el-space direction="vertical" class="services-col-main py-28px color-white wh-full">
        <div class="font-s-2.8rem font-600 sm:font-s-2rem">服务清单</div>
        <div class="i-custom-fwqd wh-48px"></div>
        <MLink class="mt-3rem flex-y-center" to="/content/912305321901290301">
          查看更多
          <span class="i-ep-d-arrow-right ml-4px wh-14px"></span>
        </MLink>
        <!-- <div class="font-s-1.4rem">最新更新时间</div>
        <div class="font-s-2.4rem font-600 sm:font-s-1.8rem">{{ data.pushTime || '--' }}</div> -->
      </el-space>
    </div>
    <el-row class="lt-xs:h-236px lt-md:overflow-y-auto lt-md:overflow-x-hidden w-full">
      <el-col :span="12" class="lt-xs:hidden">
        <el-row
          v-for="(item, index) in colData"
          :key="'item' + index"
          class="flex-grow overflow-y-auto"
          :gutter="$screen.higherThan('sm') ? 0 : 16"
          :span="12"
        >
          <el-col
            v-for="i in item"
            :key="i.text"
            :lg="12"
            :md="24"
            :sm="24"
            :xs="24"
            class="mb-16px"
          >
            <div class="h-full bg-bg-2 p-16px rounded-4px h-110px">
              <div class="font-s-18px font-600 text-color-1 mb-16px text-left">{{ i.text }}</div>
              <div class="flex justify-between items-center">
                <span :class="i.icon" class="wh-38px"></span>
                <span
                  class="text-color-1 font-s-14px px-12px py-6px rounded-24px border-1px border-solid border-primary-color"
                >
                  <MLink :to="i.link" class="text-primary-color">点击进入</MLink>
                </span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" class="pl-2rem flex flex-col h-full">
        <MiniTitle title="企业信用" icon="icon_djyl" :to="`/credit`"></MiniTitle>
        <div class="wh-full">
          <InfiniteList :list="creditList" />
        </div>
      </el-col>
      <!-- <el-col :span="24">
        <el-row
          class="h-full flex-grow overflow-y-auto"
          :gutter="$screen.higherThan('sm') ? 0 : 16"
        >
          <el-col
            v-for="item in colData2"
            :key="item.text"
            :lg="6"
            :md="12"
            :sm="12"
            :xs="24"
            class="lt-md:mb-16px"
          >
            <div class="h-full bg-bg-2 p-16px rounded-4px h-110px">
              <div class="font-s-18px font-600 text-color-1 mb-16px text-left">{{ item.text }}</div>
              <div class="flex justify-between items-center">
                <span :class="item.icon" class="wh-38px"></span>
                <span
                  class="text-color-1 font-s-14px px-12px py-6px rounded-24px border-1px border-solid border-primary-color"
                >
                  <MLink :to="item.link" class="text-primary-color">点击进入</MLink>
                </span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col> -->
    </el-row>
  </div>
</template>

<script setup lang="ts">
  import InfiniteList from './infinite-list.vue'
  import { getInfoList } from '~/api/activity/info'
  import type { IInfo } from '~/models/activity'
  import { getEnterpriseCreditListHome } from '~/api/credit/credit'
  import { EnterpriseCredit } from '~/models/credit'
  import { districts } from '~/constants'

  const $screen = useScreen()
  const span = computed(() => $screen.span.value)
  const data = ref<IInfo>({
    pushTime: '',
  })
  const colData = ref([
    [
      {
        text: '清单导读',
        icon: 'i-custom-dd',
        link: '/content/912305321901290301',
      },
      {
        text: '综合服务',
        icon: 'i-custom-dd2',
        link: '/content/912305321901290302',
      },
    ],
    [
      {
        text: '公共秩序',
        icon: 'i-custom-ggzx',
        link: '/content/912305321901290305',
      },
      {
        text: '绿化养护',
        icon: 'i-custom-lvh',
        link: '/content/912305321901290307',
      },
    ],
  ])
  // const colData2 = ref([
  //   {
  //     text: '公用设施',
  //     icon: 'i-custom-gy',
  //     link: '/content/912305321901290303',
  //   },
  //   {
  //     text: '环境维护',
  //     icon: 'i-custom-huanj',
  //     link: '/content/912305321901290306',
  //   },
  //   {
  //     text: '装饰装修',
  //     icon: 'i-custom-zszx',
  //     link: '/content/912305321901290304',
  //   },
  //   {
  //     text: '案例展示',
  //     icon: 'i-custom-anl',
  //     link: '/content/912305321901290308',
  //   },
  // ])

  const creditList = ref<EnterpriseCredit[]>([])
  const getCreditList = () => {
    getEnterpriseCreditListHome({
      pageSize: 10,
    }).then((res) => {
      creditList.value = res.data?.list
    })
  }

  getCreditList()

  getInfoList({
    pageNum: 1,
    pageSize: 1,
    isPage: true,
    classId1: '912305321901290300',
    pushTimeAtSort: 'desc',
  }).then((res) => {
    if (res.data && res.data.list) {
      if (res.data.list[0]) {
        data.value = res.data.list[0]
      }
    }
  })
</script>

<style scoped lang="scss">
  .services-col-main {
    @apply rounded-4px;
    background: linear-gradient(135deg, #64bc8c 0%, #009141 100%);
  }

  :deep(.el-table__row) {
    td {
      border: none;
    }
  }

  :deep(.el-table__inner-wrapper::before) {
    height: 0;
  }
</style>
